@import "./colors.css";

/* general */
.mainSupportBlock .row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.mainSupportBlock{
    overflow-x:hidden !important;
    overflow-y:hidden !important;
}

.ticketAddIcon  {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
}
.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.edit_header_icon_add {
    cursor: pointer;
    margin-right: 15px;
    vertical-align: sub;
    font-size: 1.5rem;
    color: var(--primary--color);
}

.error_div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}


/* header */
.supportHeader{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.headerIcons{
    float: right;
    cursor: pointer;
}

.supportListSection {
    height: calc(100vh - 180px);
    overflow-y: hidden;
    overflow-x: hidden;
    padding-right: 0px !important;
    padding-left: 0px !important;
    cursor: pointer;
    border-radius: 0px;
}

.ticketDescription {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-weight: 500;
    width: 250px;
}

.selectedTicket{
    background-color: #007bff3f;
    cursor: pointer;
    border-radius: 0px;
}
.ticket{
    background-color: white;
    cursor: pointer;
    border-radius: 0px;
}

.ticket:hover{
    background-color: #007bff1a;
}

.ticketOptions>button {
    max-width: 100px;
    min-width: 100px;
}

.selectMsg {
    margin-top: 10rem;
}

.supportDetailSection{
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-radius: 0px;
    overflow: hidden;
}



.clientInfo{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ticketInfo{
    padding-left: 0px !important;
    padding-right: 0px !important;
}




/* announcement list */
.announcementFolder{
    border: 1px solid #d9d9d9;
}

.noAnnouncementLabel{
    margin-top: 50px;
}

.detailListSection {
    height: calc(100vh - 225px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0px !important;
    padding-left: 0px !important;
    cursor: pointer;
    border-radius: 0px;
    border: 1px solid  #d9d9d9;
}

.announcementDetails{
    border: 1px solid #d9d9d9;
    border-radius: 0px;
    padding: 8px 24px;
    height: calc(100vh - 180px);
    width: 100%;
}

.filterSection{
    padding-left: 0px;
}

.margin_top_row {
    margin-top: 1rem;
}

.publishButton{
    width: 100%;
}